import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const TREE_VIEW_SELECTED_CHANGE = 'treeViewSelectedChange';

const treeViewSelectedChangeSlice = createSlice({
    name: TREE_VIEW_SELECTED_CHANGE,
    initialState: null,
    reducers: {
        setSelectedTreeViewItem: (_state, action) => {
            return action.payload;
        },
    },
});

export default treeViewSelectedChangeSlice.reducer;

export const { setSelectedTreeViewItem } = treeViewSelectedChangeSlice.actions;

export const useGetTreeViewSelected = () =>
    useSelector((state) => state[TREE_VIEW_SELECTED_CHANGE]);
