import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const FLAG_DETAILS_BACK_ROUTE = 'flagDetailsBackRoute';

const flagDetailsBackSlice = createSlice({
    name: FLAG_DETAILS_BACK_ROUTE,
    initialState: null,
    reducers: {
        setBackButtonRoute: (_state, action) => {
            return action.payload;
        },
    },
});

export default flagDetailsBackSlice.reducer;

export const { setBackButtonRoute } = flagDetailsBackSlice.actions;

export const useGetBackButtonRoute = () =>
    useSelector((state) => state[FLAG_DETAILS_BACK_ROUTE]);
