import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { useSelector } from 'react-redux';

export default function SideNavOuterToolbar({ title, children }) {
    const scrollViewRef = useRef(null);
    const navigate = useNavigate();
    const { isXSmall, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(
        isLarge ? MenuStatus.Opened : MenuStatus.Closed
    );
    const scroll = useSelector((state) => state.rulesScroll);
    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus((prevMenuStatus) =>
            prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        );
        event.stopPropagation();
    }, []);
    const { pathname } = useLocation();
    //Current Drawer

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus((prevMenuStatus) =>
            prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        );
    }, []);

    useEffect(() => {
        window.addEventListener('dragover', (event) => {
            const isNearBottom = window.innerHeight - event.clientY < 100;
            const isNearTop = event.clientY < 100;

            if (isNearTop) {
                scrollViewRef.current.instance.scrollBy({ left: 0, top: -10 });
            }

            if (isNearBottom) {
                scrollViewRef.current.instance.scrollBy({ left: 0, top: 10 });
            }
        });
    }, []);

    useEffect(() => {
        scrollViewRef.current.instance.scrollTo(0);
    }, [scroll]);

    const onOutsideClick = useCallback(() => {
        setMenuStatus((prevMenuStatus) =>
            prevMenuStatus !== MenuStatus.Closed && !isLarge
                ? MenuStatus.Closed
                : prevMenuStatus
        );
        return menuStatus === MenuStatus.Closed ? true : false;
    }, [isLarge]);

    const onNavigationChanged = useCallback(
        ({ itemData, event, node }) => {

            if (pathname !== itemData.path) {
                navigate(itemData.path);
            }

            if (
                menuStatus === MenuStatus.Closed ||
                !itemData.path ||
                node.selected
            ) {
                event.preventDefault();
                return;
            }

            scrollViewRef.current.instance.scrollTo(0);
            if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
                setMenuStatus(MenuStatus.Closed);
                event.stopPropagation();
            }
        },
        [navigate, menuStatus, isLarge, pathname]
    );

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header menuToggleEnabled toggleMenu={toggleMenu} title={title} />
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={0}
                // minSize={isXSmall ? 0 : 130}
                maxSize={250}
                shading={isLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={'menu'}
            >
                <div className={'container'}>
                    <ScrollView
                        ref={scrollViewRef}
                        className={
                            'layout-body with-footer dx-theme-background-color'
                        }
                        direction={'both'}
                        height={'70%'}
                    >
                        <div className={'content'}>
                            {React.Children.map(children, (item) => {
                                return item.type !== Footer && item;
                            })}
                        </div>
                        <div className={'content-block'}>
                            {React.Children.map(children, (item) => {
                                return item.type === Footer && item;
                            })}
                        </div>
                    </ScrollView>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                        outer={true}
                    ></SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3,
};
