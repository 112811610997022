import moment from 'moment';
import { onlyWhiteSpaceRegex } from './vars';

export const isValidStringValue = (value) =>
    !!value && !onlyWhiteSpaceRegex.test(value);

export function getFilterParams(filters, filterSelectors, type) {
    const dateSelectors = {
        dateCreated: {
            from: 'startDate',
            to: 'endDate',
        },
        date: {
            from: 'dateFrom',
            to: 'dateTo',
        },
        resolutionDate: {
            from: 'resolutionDateFrom',
            to: 'resolutionDateTo',
        },
        dueDate: {
            from: 'dueDateFrom',
            to: 'dueDateTo',
        },
    };
    
    const getObjFilterData = (obj, filter) => {
        if (typeof filter.filterValue === 'object') {
            let selector = filter[0];
            let fromSelector = '';
            let toSelector = '';

            if (typeof selector === 'object') {
                selector = selector[0];
            }

            if (typeof selector === 'string') {
                fromSelector = dateSelectors[selector].from;
                toSelector = dateSelectors[selector].to;
            }

            if (filter[1] === 'and') {
                const startDate = moment(filter[0][2]).format('yyyy-MM-DD');
                const endDate = moment(filter[2][2]).format('yyyy-MM-DD');

                obj[fromSelector] = startDate;
                obj[toSelector] = endDate;
            } else {
                const isLessThan = filter[1] === '<';

                const date = moment(filter[2]).format('yyyy-MM-DD');

                if (isLessThan) {
                    obj[toSelector] = date;
                } else {
                    obj[fromSelector] = date;
                }
            }
        } else if (typeof filter[0] === 'string') {
            const selector = filter[0];
            obj[filterSelectors[selector]] = filter[2];
        }
    };

    let obj = {};
    if (!filters) {
        return {};
    }

    if (
        typeof filters.filterValue === 'object' ||
        typeof filters[0] === 'string'
    ) {
        getObjFilterData(obj, filters);
    } else {
        filters.forEach((filter) => {
            if (filter !== 'and') {
                getObjFilterData(obj, filter);
            }
        });
    }

    if (type === 'object') {
        return obj;
    }

    return Object.keys(obj)
        .map((key) => {
            const value = obj[key];

            return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
}


export function getDirection(sort) {
    if (sort) {
        return sort[0].desc ? 'DESC' : 'ASC';
    }

    return 'DESC';
}

export const getAxiosResponseError = (err) => err?.response?.data?.message;

export const getCurrentRuleVersion = (ruleHistory, id) => {
    let v;

    ruleHistory?.forEach((e) => {
        if (e.ruleId === id) {
            v = e.version;
        }
    });

    return v;
};

export const getPreviousVersionDate = (ruleHistory, currentRuleVersion) => {
    let date;

    ruleHistory?.forEach((e) => {
        if (
            e.version === currentRuleVersion - 1 ||
            e.changeType === 'ACTIVATED'
        ) {
            date = e.activeSince;
        }
    });

    return date;
};

export const getRightType = (
    droppedFieldType,
    oldDroppedFieldType,
    oldRightType
) => {
    console.log('get right type');
    let type = '';

    switch (droppedFieldType) {
        case 'DATE':
            type = 'DATE';
            break;
        case 'BOOL':
            type = 'BOOL';
            break;
        default:
            type = 'FIELD';
    }

    if (oldDroppedFieldType) {
        let oldType = '';

        switch (oldDroppedFieldType) {
            case 'DATE':
                oldType = 'DATE';
                break;
            case 'BOOL':
                oldType = 'BOOL';
                break;
            default:
                oldType = 'FIELD';
        }

        if (type === oldType) {
            return oldRightType;
        }
    }

    return type;
};
