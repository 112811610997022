import {
    Button,
    DataGrid,
    DateBox,
    Popup,
    SelectBox,
    TextBox,
} from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { changeRuleStatus, cloneRule } from '../../../services/rules';
import {
    languageLables,
    periodItems,
    useGetLocalizedLabel,
} from '../../../utils/vars';
import useGetLabel from '../../../hooks/useFindLabel';
import { useCheckPermission } from '../../../hooks/useCheckPermission';
import { useRuleContext } from '../ruleContext';
import { Link, useNavigate } from 'react-router-dom';
import { useActionsRoleContext } from '../../../contexts/actionsRoleProvider';
import { useToastContext } from '../../../components/toast/toast';
import {
    getAxiosResponseError,
    getCurrentRuleVersion,
    getPreviousVersionDate,
} from '../../../utils/functions';

const moment = require('moment');

export default function DetailsPopup({
    open,
    onClose,
    status,
    refetchRuleHistory,
    ruleHistory,
    isLoading,
}) {
    const { role } = useActionsRoleContext();
    const { rule } = useRuleContext();
    const [openedSections, setOpenedSections] = useState({
        activate: false,
        clone: false,
    });
    const hasOpenSections = openedSections?.activate || openedSections?.clone;
    const [closing, setClosing] = useState(0);
    const active = status === 'ACTIVATED';
    const lg = useSelector((state) => state.language);
    const activateRulePermission = useCheckPermission('ACTIVATE_RULE');
    const deactivateRulePermission = useCheckPermission('DEACTIVATE_RULE');
    const cloneRolePermission = role === 'ADMIN' || role === 'GRANT_ADMIN';
    const cloneOpen = openedSections?.clone;
    const currentRuleVersion = getCurrentRuleVersion(ruleHistory, rule.id);
    const previousVersionDate = getPreviousVersionDate(
        ruleHistory,
        currentRuleVersion
    );
    const getLocalized = useGetLocalizedLabel();

    const { showErrorToast } = useToastContext();

    useEffect(() => {
        if (open && ruleHistory === null) {
            showErrorToast();
        }
    }, [open]);

    function closeHanlder() {
        setClosing((oldState) => oldState + 1);
        onClose();
        setOpenedSections({ activate: false, clone: false });
    }

    return (
        <Popup
            visible={open}
            hideOnOutsideClick={true}
            onHiding={closeHanlder}
            title="Details"
            width="auto"
            height={'auto'}
            minHeight={150}
            minWidth={200}
            dragEnabled={false}
            maxWidth={900}
            maxHeight={700}
            showCloseButton={true}
        >
            <div style={{ margin: '10px 0 10px 0' }}>
                <div
                    style={{
                        display: hasOpenSections ? 'block' : 'flex',
                        gap: '10px',
                        alignItems: 'end',
                    }}
                >
                    {activateRulePermission && active && (
                        <>
                            <ActivateDeactivate
                                openedSections={openedSections}
                                isLoading={isLoading}
                                closing={closing}
                                isActive={true}
                                refetchRuleHistory={refetchRuleHistory}
                                setOpenedSections={setOpenedSections}
                                previousVersionDate={
                                    new Date(previousVersionDate)
                                }
                            />
                        </>
                    )}

                    {deactivateRulePermission && !active && (
                        <ActivateDeactivate
                            openedSections={openedSections}
                            isLoading={isLoading}
                            closing={closing}
                            isActive={false}
                            refetchRuleHistory={refetchRuleHistory}
                            setOpenedSections={setOpenedSections}
                            previousVersionDate={new Date(previousVersionDate)}
                        />
                    )}
                    {cloneRolePermission && (
                        <Clone
                            setOpenedSections={setOpenedSections}
                            closing={closing}
                            openedSections={openedSections}
                        />
                    )}
                    {cloneRolePermission && cloneOpen && (
                        <p className="clone-rule-label">
                            Cloning would clone the currently selected rule
                            version which is not the last one.
                        </p>
                    )}
                </div>
                <p style={{ fontSize: '18px', textAlign: 'center' }}>
                    {getLocalized('changeHistory')}
                </p>
                <Table ruleHistory={ruleHistory} />
            </div>
        </Popup>
    );
}

function ActivateDeactivate({
    isActive,
    closing,
    refetchRuleHistory,
    isLoading,
    openedSections,
    setOpenedSections,
    previousVersionDate,
}) {
    const { id } = useParams();
    const lg = useSelector((state) => state.language);
    const open = openedSections?.activate;
    const [selectedDate, setSelectedDate] = useState(null);
    const [period, setPeriod] = useState(null);
    const { refetchRule } = useRuleContext();
    const { showErrorToast } = useToastContext();
    const getLocalized = useGetLocalizedLabel();

    const isPreviousDateValid =
        previousVersionDate &&
        typeof previousVersionDate === 'object' &&
        !isNaN(previousVersionDate.getTime());

    useEffect(() => {
        setSelectedDate(null);
    }, [closing]);

    async function confirmHandler() {
        const postData = {
            id,
            date: selectedDate,
            status: isActive ? 'DEACTIVATED' : 'ACTIVATED',
            period,
        };

        try {
            await changeRuleStatus(postData);
            refetchRule();
            refetchRuleHistory();
            setOpenedSections((oldState) => {
                return { ...oldState, activate: false };
            });
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'end',
                justifyContent: 'space-between',
                width: open ? '100%' : 'auto',
                marginBottom: '10px',
            }}
        >
            <div>
                <Button
                    type="default"
                    stylingMode="outlined"
                    text={
                        isActive
                            ? getLocalized('deactivate')
                            : getLocalized('activate')
                    }
                    onClick={() => {
                        setOpenedSections((oldState) => {
                            return { ...oldState, activate: !open };
                        });
                    }}
                />
            </div>
            {open && (
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'end',
                    }}
                >
                    {!isActive && (
                        <SelectBox
                            stylingMode={'underlined'}
                            width={'100px'}
                            items={periodItems}
                            displayExpr={'label'}
                            valueExpr={'value'}
                            label="Period"
                            onValueChanged={(ev) => setPeriod(ev.value)}
                        />
                    )}

                    <div>
                        <DateBox
                            // defaultValue={now}
                            stylingMode="underlined"
                            label={languageLables.fromDate[lg]}
                            min={
                                isPreviousDateValid
                                    ? previousVersionDate
                                    : new Date()
                            }
                            onValueChanged={(e) =>
                                setSelectedDate(
                                    moment(e.value).format('YYYY-MM-DD')
                                )
                            }
                        />
                    </div>
                    <Button
                        disabled={
                            (isActive
                                ? !selectedDate
                                : !period || !selectedDate) || isLoading
                        }
                        type="default"
                        text={languageLables.confirm[lg]}
                        onClick={confirmHandler}
                    />
                </div>
            )}
        </div>
    );
}

function Clone({ setOpenedSections, closing, openedSections }) {
    const { id } = useParams();
    const [nameValue, setNameValue] = useState('');
    const lg = useSelector((state) => state.language);
    const open = openedSections?.clone;
    useEffect(() => {
        setNameValue('');
    }, [closing]);
    const { showErrorToast, showSuccessToast } = useToastContext();

    async function confirmHandler() {
        try {
            await cloneRule({
                id,
                name: nameValue,
            });
            showSuccessToast('Rule successfully cloned');
        } catch (err) {
            console.error(err);
            showErrorToast(getAxiosResponseError(err));
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'end',
                justifyContent: 'space-between',
                width: open ? '100%' : 'auto',
                marginBottom: '10px',
            }}
        >
            <div>
                <Button
                    type="default"
                    stylingMode="outlined"
                    text={'Clone'}
                    onClick={() => {
                        setOpenedSections((oldState) => {
                            return { ...oldState, clone: !open };
                        });
                    }}
                />
            </div>
            {open && (
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'end',
                    }}
                >
                    <div>
                        <TextBox
                            stylingMode="underlined"
                            label={languageLables.newRuleName[lg]}
                            value={nameValue}
                            onInput={(e) =>
                                setNameValue(e.event.currentTarget.value)
                            }
                        />
                    </div>
                    <Button
                        disabled={!nameValue}
                        type="default"
                        text={languageLables.confirm[lg]}
                        onClick={confirmHandler}
                    />
                </div>
            )}
        </div>
    );
}

function Table({ ruleHistory }) {
    const navigate = useNavigate();
    const lg = useSelector((state) => state.language);

    return (
        <DataGrid
            hoverStateEnabled={true}
            onRowClick={(data) =>
                navigate(`/rule-dashboard/${data?.data?.ruleId}`)
            }
            keyExpr={'id'}
            dataSource={ruleHistory}
            // dataRowRender={Row}
            dataRowComponent={Row}
        >
            <Paging defaultPageSize={5} />
            <Column
                allowSorting={false}
                allowResizing={true}
                dataField={'changeDate'}
                dataType="datetime"
                caption={useGetLabel('Date', lg)}
            />
            <Column
                allowSorting={false}
                allowResizing={true}
                dataField={'changeType'}
                caption={'Change type'}
            />
            <Column
                allowSorting={false}
                allowResizing={true}
                dataField={'activeSince'}
                dataType="date"
                caption={'Since date'}
            />
            <Column
                allowSorting={false}
                allowResizing={true}
                alignment="left"
                dataField={'period'}
                caption={'Period '}
            />
            <Column
                allowSorting={false}
                allowResizing={true}
                alignment="left"
                dataField={'version'}
                caption={'Version '}
            />

            <Column
                allowSorting={false}
                allowResizing={true}
                cellRender={(d) => {
                    return <p>{d.data.author?.name}</p>;
                }}
                caption={'User'}
            />
        </DataGrid>
    );
}

function Row({ data }) {
    const { data: rowData } = data;
    const { rule } = useRuleContext();
    let rowClasses = 'dx-row dx-data-row dx-row-lines custom-row';

    if (rowData.ruleId === rule.id) {
        rowClasses += ' current-rule-row';
    }

    return (
        <tr className={rowClasses}>
            <td>
                {rowData.changeDate &&
                    moment(rowData.changeDate).format('M/D/YYYY, h:mm A')}
            </td>
            <td>{rowData.changeType}</td>
            <td>
                {rowData.activeSince &&
                    moment(rowData.activeSince).format('M/D/YYYY')}
            </td>
            <td>{rowData.period}</td>
            <td>{rowData.version}</td>
            <td>{rowData.author?.name}</td>
        </tr>
    );
}
