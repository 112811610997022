import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        setUser: (_state, action) => {
            return action.payload;
        },
    },
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;

export const useGetUserData = () => useSelector((state) => state.user);
