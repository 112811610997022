import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const DEFAULT_TABLE_FILTERS = 'defaultTableFilters';

const initialState = { monitoringQueue: {} };

const defaultTableFiltersSlice = createSlice({
    name: DEFAULT_TABLE_FILTERS,
    initialState,
    reducers: {
        setFilters: (state, { payload: { key, filter } }) => {
            state[key][filter.column] = {
                value: filter.value,
                operator: filter.operator,
            };
        },
        clearFilter: (state, { payload: key }) => {
            state[key] = {};
        },
    },
});

export default defaultTableFiltersSlice.reducer;

export const { setFilters, clearFilter } = defaultTableFiltersSlice.actions;

export const useGetDefaultFilters = () =>
    useSelector((state) => state[DEFAULT_TABLE_FILTERS]);
