import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './SideNavigationMenu.scss';

import * as events from 'devextreme/events';
import { useGetLocalizedLabel } from '../../utils/vars';
import { useSelector } from 'react-redux';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import { useActionsRoleContext } from '../../contexts/actionsRoleProvider';
import { useNavigate } from 'react-router-dom';
import { selectUnselect } from '../../redux/features/unselectTreeView';
import { useGetTreeViewSelected } from '../../redux/features/treeViewChangeSelected';

export default function SideNavigationMenu(props) {
    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady,
        outer,
    } = props;
    const lg = useSelector((state) => state.language);
    const getLocalized = useGetLocalizedLabel();
    const { tenancy } = useActionsRoleContext();
    const hasReportsPermission = useCheckPermission('REVIEW_REPORTS');
    const hasAuditTrailPermission = useCheckPermission('REVIEW_AUDIT');
    const hasLogsPermission = useCheckPermission('LOGS');
    const hasMonitoringPermission = useCheckPermission(
        tenancy === 'SINGLE' ? 'REVIEW_MONITORING' : 'SEARCH_REVIEW_MONITORING'
    );
    const hasManualSearchPermission = useCheckPermission(
        tenancy === 'SINGLE' ? 'MANUAL_SEARCH' : 'MANUAL_SEARCH_MULTI'
    );
    const hasFetchingError = useSelector((state) => state.errorFetchingActions);
    const { isLarge } = useScreenSize();

    const nav = getNav();
    const unselectTreeView = useSelector(selectUnselect);
    const treeViewRef = useRef(null);



    //Template side menu

    function getNav() {
        const navArr = [
            {
                text: 'Home',
                path: '/',
                id: 1,
            },
            {
                text: getLocalized('rules'),
                path: '/rules',
                id: 2,
            },
        ];

        if (hasMonitoringPermission) {
            navArr.push({
                text: getLocalized('monitoringQueue'),
                path: '/monitoring',
                id: 3,
            });
        }

        if (hasReportsPermission) {
            navArr.push({
                text: getLocalized('reports'),
                path: null,
                id: 4,
                items: [
                    {
                        text: getLocalized('singleReports'),
                        path: '/reports-single',
                        id: 5,
                    },
                    {
                        text: getLocalized('multipleReports'),
                        path: '/reports-multiple',
                        id: 6,
                    },
                    {
                        text: getLocalized('dailyOverlimitReports'),
                        path: '/reports-daily-overlimit',
                        id: 7,
                    },
                    {
                        text: getLocalized('forMonitoringReports'),
                        path: '/reports-for-monitoring',
                        id: 7,
                    },
                    {
                        text: getLocalized('customReports'),
                        path: '/reports-custom',
                        id: 8,
                    },
                ],
            });
        }

        if (hasAuditTrailPermission) {
            navArr.push({
                text: getLocalized('auditTrail'),
                path: '/audit-trail',
                id: 9
            });
        }

        if (hasLogsPermission) {
            navArr.push({
                text: getLocalized('activityLog'),
                path: '/activity-log',
                id: 10
            });
        }

        if (hasManualSearchPermission) {
            navArr.push({
                text: getLocalized('manualSearch'),
                path: '/manual-search',
                id: 11
            });
            navArr.push({
                text: getLocalized('advancedSearch'),
                path: '/advanced-search',
                id: 12
            });
        }

        navArr.push({
            text: getLocalized('settings'),
            path: '/settings',
            id: 13
        });

        return navArr;
    }

    function normalizePath() {
        return nav.map((item) => ({
            ...item,
            // expanded: isLarge,
            path:
                item.path && !/^\//.test(item.path)
                    ? `/${item.path}`
                    : item.path,
        }));
    }

    const items = useMemo(
        normalizePath,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lg]
    );

    const {
        navigationData: { currentPath },
    } = useNavigation();


    const wrapperRef = useRef();
    const getWrapperRef = useCallback(
        (element) => {
            const prevElement = wrapperRef.current;
            if (prevElement) {
                events.off(prevElement, 'dxclick');
            }

            wrapperRef.current = element;
            events.on(element, 'dxclick', (e) => {
                openMenu(e);
            });
        },
        [openMenu]
    );

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;

        if (treeView) {
            treeView.unselectAll();
        }
    }, [unselectTreeView]);

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
            // treeView.expandItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode]);

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            {!hasFetchingError && (
                <div className={'menu-container'}>
                    <TreeView
                        ref={treeViewRef}
                        items={items}
                        keyExpr={'text'}
                        selectionMode={'single'}
                        focusStateEnabled={false}
                        expandEvent={'click'}
                        onItemClick={selectedItemChanged}
                        onContentReady={onMenuReady}
                        width={'100%'}
                        selectByClick={true}
                    />
                </div>
            )}
        </div>
    );
}
