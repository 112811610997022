import {
    Button,
    DataGrid,
    LoadIndicator,
    Popup,
    SelectBox,
    TextArea,
} from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
    changeAmlRisk,
    getFlagById,
    getFlaggedDataColumns,
} from '../../services/flag';
import { errorMsg, languageLables, routes } from '../../utils/vars';
import './flagDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import { useActionsRoleContext } from '../../contexts/actionsRoleProvider';
import Assign from './Assign/Assign';
import Unlock from './Unlock/Unlock';
import Escalate from './Escalate/Escalate';
import Resolution from './Resolution/Resolution';
import Details from './Details/Details';
import ResolutionStatusHistory from './ResolutionStatusHistory/ResolutionStatusHistory';
import StatusReviewHistory from './StatusReviewHistory/StatusReviewHistory';
import AmlHistoryPopup from './AmlHistoryPopup/AmlHistoryPopup';
import DocumentsTable from './DocumentsTable/DocumentsTable';
import ExportBtn from '../../components/exportBtn/exportBtn';
import { reactQueryOptions } from '../../utils/reactQueryOptions';
import AssignTo from './AssignTo/AssignTo';
import { isValidStringValue } from '../../utils/functions';
import { useToastContext } from '../../components/toast/toast';
import { setBackButtonRoute, useGetBackButtonRoute } from '../../redux/features/flagDetailsBackButtonRoute';

export default function FlagDetails() {
    const { role } = useActionsRoleContext();
    const navigate = useNavigate();
    const [historyPopupOpen, setPopupOpen] = useState(false);
    const { id } = useParams();
    const {
        data: flagData,
        isLoading,
        error,
        refetch,
        isFetching,
    } = useQuery('flagData', () => getFlagById(id), reactQueryOptions);
    const {
        data: columnsData,
        isLoading: isLoadingColumnsData,
        error: errorFetchingColumnsData,
    } = useQuery(
        'flagged-data-columns',
        () => getFlaggedDataColumns(id),
        reactQueryOptions
    );

    const resolutionTextPermission = useCheckPermission('RESOLUTION_TEXT');
    const amlRiskPermisson = useCheckPermission('AML_INCREASE_RISK');
    const escalatePermission = useCheckPermission('ESCALATE');
    const isEscalated = flagData?.flagDetails.status === 'ESCALATED';
    const assignRolePermission =
        role === 'AML_CORDINATOR' || role === 'GRANT_ADMIN';
    const {showErrorToast} = useToastContext()
    const {
        locked,
        assigneeId,
        status: flagStatus,
        dueDate,
        dueDateCheck,
        resolution: resolutionStatus,
    } = flagData?.flagDetails || {};
    const backButtonRoute = useGetBackButtonRoute();
    const dispatch = useDispatch();

    console.log('backButtonRoute', backButtonRoute);
    console.log('route', backButtonRoute || routes.MONITORING_QUEUE);
    
    useEffect(() => {
        if (error) {
            showErrorToast(error?.response?.data?.message || errorMsg);
        }

        return () => {
            dispatch(setBackButtonRoute(null));
        }
    }, [error]);

    useEffect(() => {
        if (errorFetchingColumnsData) {
            showErrorToast("Can't get flagged data. Try again later.");
        }
    }, [errorFetchingColumnsData]);

    const lg = useSelector((state) => state.language);
    if (isLoading || isLoadingColumnsData || isFetching) {
        return (
            <div className="load-indicator-wrapper">
                <LoadIndicator />
            </div>
        );
    }
    return (
        <>
            {!error ? (
                <div className="flag-details-wrapper">
                    <Stack flexDirection="row" justifyContent="space-between">
                        {/* <DownloadFlag /> */}
                        <ExportBtn
                            exportUrl={`flag/export?flagId=${id}`}
                            method="POST"
                            name="flag"
                        />
                        <Stack flexDirection="row" gap="10px" alignItems="end">
                            {escalatePermission && !isEscalated && (
                                <Escalate refetch={refetch} />
                            )}
                            {assignRolePermission && !assigneeId && (
                                <Assign
                                    assigneeId={assigneeId}
                                    refetch={refetch}
                                />
                            )}

                            {locked && !!assigneeId ? (
                                <Unlock refetch={refetch} />
                            ) : (
                                !isEscalated && (
                                    <AssignTo refetch={refetch}/>
                                )
                            )}
                            {amlRiskPermisson && (
                                <AmlButton refetch={refetch} />
                            )}

                            <div>
                                <Button
                                    stylingMode="outlined"
                                    type="default"
                                    text={languageLables.history[lg]}
                                    onClick={() => setPopupOpen(true)}
                                />
                            </div>
                            <div>
                                <Button
                                    text={'Back'}
                                    type="default"
                                    onClick={() => navigate(backButtonRoute || routes.MONITORING_QUEUE)}
                                />
                            </div>
                        </Stack>
                    </Stack>

                    <div className="header-section">
                        <div className="table">
                            <Details
                                date={flagData?.flagDetails.date}
                                status={flagData?.flagDetails.status}
                                rule={flagData?.flagDetails.name}
                                tenant={flagData?.flagDetails.tenant}
                                data={flagData?.flagDetails.data}
                                assignee={flagData?.flagDetails.assignee}
                                resolutionStatus={resolutionStatus}
                            />
                        </div>
                        <div className="select-section">
                            <Resolution
                                refetch={refetch}
                                resolutionStatus={
                                    flagData?.flagDetails.resolution
                                }
                                isEscalated={isEscalated}
                                disabled={locked}
                                flagStatus={flagStatus}
                                dueDate={dueDate}
                                dueDateCheck={dueDateCheck}
                            />
                        </div>
                    </div>
                    {!errorFetchingColumnsData && (
                        <div className="table flagged-data">
                            <FlaggedData
                                dataSource={flagData?.flaggedData?.map(
                                    (e) => e.data
                                )}
                                columnsData={columnsData}
                            />
                        </div>
                    )}

                    <Stack
                        alignItems="baseline"
                        flexDirection="row"
                        gap="10px"
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        {resolutionTextPermission && (
                            <ResolutionStatusHistory
                                dataSource={
                                    flagData?.resolutionStatusHistory &&
                                    flagData?.resolutionStatusHistory.slice(
                                        0,
                                        3
                                    )
                                }
                            />
                        )}

                        <StatusReviewHistory
                            dataSource={
                                flagData?.reviewStatusHistory &&
                                flagData?.reviewStatusHistory.slice(0, 3)
                            }
                        />

                        <DocumentsTable
                            documentsData={flagData?.files}
                            refetch={refetch}
                        />
                    </Stack>
                    <AmlHistoryPopup
                        open={historyPopupOpen}
                        close={() => setPopupOpen(false)}
                        id={id}
                    />
                </div>
            ) : (
                <div className="back-btn-error">
                    <Button
                        text={'Back'}
                        type="default"
                        onClick={() => navigate(backButtonRoute || routes.MONITORING_QUEUE)}
                    />
                </div>
            )}
        </>
    );
}

function AmlButton({ refetch }) {
    const { id } = useParams();
    const lg = useSelector((state) => state.language);
    const [popupOpen, setPopupOpen] = useState(false);
    const [amlSelectValue, setAmlSelectValue] = useState('');
    const [description, setDescription] = useState('');
    const amlSelectItems = ['Increase', 'Decrease'];
    const hasDescription = isValidStringValue(description);
    const { showErrorToast } = useToastContext();

    async function confirmHandler() {
        try {
            await changeAmlRisk({
                id,
                increase: amlSelectValue === 'Increase' ? true : false,
                description,
            });
            refetch();
        } catch (err) {
            console.error(err);
            showErrorToast(err.response?.data.message);
        } finally {
            setPopupOpen(false);
        }
    }

    return (
        <div>
            <Button
                stylingMode="outlined"
                type="default"
                text={languageLables.changeRisk[lg]}
                onClick={() => setPopupOpen(true)}
            />
            <Popup
                showTitle={false}
                open={popupOpen}
                visible={popupOpen}
                maxWidth="280px"
                maxHeight="200px"
                // height='auto'
                hideOnOutsideClick={true}
                onHiding={() => setPopupOpen(false)}
            >
                <SelectBox
                    items={amlSelectItems}
                    stylingMode="underlined"
                    onValueChange={(v) => setAmlSelectValue(v)}
                />
                <TextArea
                    label="Description*"
                    stylingMode="underlined"
                    onInput={(ev) =>
                        setDescription(ev.event.currentTarget.value)
                    }
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '10px',
                    }}
                >
                    <Button
                        text="Cancel"
                        onClick={() => setPopupOpen(false)}
                        type="danger"
                        stylingMode="outlined"
                    />
                    <Button
                        text="Confirm"
                        type="success"
                        stylingMode="outlined"
                        onClick={confirmHandler}
                        disabled={!amlSelectValue || !hasDescription}
                    />
                </div>
            </Popup>
        </div>
    );
}

function FlaggedData({ dataSource, columnsData }) {
    const lg = useSelector((state) => state.language);

    const columns = columnsData?.map((e, i) => (
        <Column
            dataType={e.includes('date') ? 'datetime' : 'string'}
            key={i}
            dataField={e}
            allowSorting={false}
        />
    ));

    return (
        <>
            <h5>{languageLables.flaggedData[lg]}</h5>
            <DataGrid dataSource={dataSource}>{columns}</DataGrid>
        </>
    );
}
